import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetLimitsRequest,
    Limit,
    LimitCurrency,
    LimitPeriod, LimitProduct,
    LimitUnit,
    SaveLimitsRequest
} from "@/store/modules/limit/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        list: [] as Limit[],
        units: [] as LimitUnit[],
        currencies: [] as LimitCurrency[],
        periods: [] as LimitPeriod[],
        products: [] as LimitProduct[],
    },
    getters: {
        limits(state): Limit[] {
            return state.list;
        },
        limitUnits(state): LimitUnit[] {
            return state.units;
        },
        limitCurrencies(state): LimitCurrency[] {
            return state.currencies;
        },
        limitPeriods(state): LimitPeriod[] {
            return state.periods;
        },
        limitProducts(state): LimitProduct[] {
            return state.products;
        },
    },
    mutations: {
        setList(state, data) {
            state.list = data;
        },
        setUnits(state, data) {
            state.units = data;
        },
        setCurrencies(state, data) {
            state.currencies = data;
        },
        setPeriods(state, data) {
            state.periods = data;
        },
        setProducts(state, data) {
            state.products = data;
        },
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const response  = await api.get<Limit[]>('api/lk/v1/limits', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getListWithSave(ctx, request: GetLimitsRequest): Promise<Limit[]> {
            const data = await ctx.dispatch('fetchList', request);
            ctx.commit('setList', data);
            return data;
        },
        async getUnits(ctx): Promise<LimitUnit[]> {
            const response  = await api.get<LimitUnit[]>('api/lk/v1/limit_units');
            ctx.commit('setUnits', response.data);

            return response.data;
        },
        async getCurrencies(ctx): Promise<LimitCurrency[]> {
            const response  = await api.get<LimitCurrency[]>('api/lk/v1/limit_currencies');
            ctx.commit('setCurrencies', response.data);

            return response.data;
        },
        async getPeriods(ctx): Promise<LimitPeriod[]> {
            const response  = await api.get<LimitPeriod[]>('api/lk/v1/limit_periods');
            ctx.commit('setPeriods', response.data);

            return response.data;
        },
        async getProducts(ctx): Promise<LimitProduct[]> {
            const response  = await api.get<LimitProduct[]>('api/lk/v1/limit_products');
            ctx.commit('setProducts', response.data);

            return response.data;
        },
        async saveLimits(ctx, request: SaveLimitsRequest) {
            await api.post('api/lk/v1/limits', request);
        },
    }
};