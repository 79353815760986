import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {OilGroup} from "@/store/modules/oilGroup/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        list: [] as OilGroup[],
    },
    getters: {
        oilGroups(state): OilGroup[] {
            return state.list;
        }
    },
    mutations: {
        setList(state, list) {
            state.list = list;
        },
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx): Promise<OilGroup[]> {
            const response  = await api.get<OilGroup[]>('api/lk/v1/oil_groups');
            return response.data;
        },
        async getListWithoutSave(ctx): Promise<OilGroup[]> {
            return await ctx.dispatch('fetchList');
        },
        async getListWithSave(ctx): Promise<OilGroup[]> {
            const data = await ctx.dispatch('fetchList');
            ctx.commit('setList' , data);

            return data;
        },
    }
};