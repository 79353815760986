<template>
  <v-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | ${siteName}` : siteName }}</template>
    </metainfo>
    <router-view/>
    <notifications />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {Notifications} from "@kyvg/vue3-notification";
import {mapGetters} from "vuex";
import { useMeta } from 'vue-meta';
import {Layout} from "@/helpers/enums/layout";

export default defineComponent({
  setup () {
    useMeta({
      title: '',
    })

    return {Layout};
  },
  name: 'App',
  components: {Notifications},
  computed: {
    ...mapGetters('auth', [
        'userData',
    ]),
    siteName(): string {
      return process.env.VUE_APP_SITE_NAME ? process.env.VUE_APP_SITE_NAME : '';
    },
    layout(): Layout {
      if (this.windowWidth < 768) {
        return Layout.Mobile;
      } else if (this.windowWidth < 1440) {
        return Layout.Tablet;
      } else {
        return Layout.Desktop;
      }
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    if (this.userData === null && location.pathname !== '/login') {
      this.$router.push({ name: 'login' });
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    userData() {
      if (this.userData === null) {
        this.$router.push({ name: 'login' });
      }
    },
  }
})
</script>
