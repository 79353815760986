import {ActionTree, Module} from "vuex";
import api from "@/plugins/api";
import {
    GetRestrictionsRequest,
    Restriction, RestrictionProduct,
    RestrictionType,
    SaveRestrictionsRequest
} from "@/store/modules/restriction/models";

export default <Module<any, any>>{
    namespaced: true,
    state: {
        types: [] as RestrictionType[],
        products: [] as RestrictionProduct[],
        list: [] as Restriction[],
    },
    getters: {
        restrictionTypes(state): RestrictionType[] {
            return state.types;
        },
        restrictions(state): Restriction[] {
            return state.list;
        },
        restrictionProducts(state): RestrictionProduct[] {
            return state.products;
        },
    },
    mutations: {
        setTypes(state, data) {
            state.types = data;
        },
        setProducts(state, data) {
            state.products = data;
        },
        setList(state, data) {
            state.list = data;
        }
    },
    actions: <ActionTree<any, any>>{
        async fetchList(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            const response  = await api.get<Restriction[]>('api/lk/v1/restrictions', { params: request });
            return response.data;
        },
        async getListWithoutSave(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            return await ctx.dispatch('fetchList', request);
        },
        async getListWithSave(ctx, request: GetRestrictionsRequest): Promise<Restriction[]> {
            const data =  await ctx.dispatch('fetchList', request);
            ctx.commit('setList', data);
            return data;
        },
        async getTypes(ctx): Promise<RestrictionType[]> {
            const response  = await api.get<RestrictionType[]>('api/lk/v1/restriction_types');
            ctx.commit('setTypes', response.data);

            return response.data;
        },
        async getProducts(ctx): Promise<RestrictionProduct[]> {
            const response  = await api.get<RestrictionProduct[]>('api/lk/v1/restriction_products');
            ctx.commit('setProducts', response.data);

            return response.data;
        },
        async saveRestrictions(ctx, request: SaveRestrictionsRequest) {
            await api.post('api/lk/v1/restrictions', request);
        }
    }
};